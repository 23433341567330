import Container from "./containers";
import React from "react";
import { AuthProvider } from "@descope/react-sdk";

const resp = await (
  await fetch("https://1.1.1.1/dns-query?name=cname.tom-sso.descope.team", {
    headers: { accept: "application/dns-json" },
  })
).json();
const backendHost = resp.Answer[0].data;


const AppRoot = () => {

  const conf = {
    "cname.descope.com.": {
      env: "old",
      projectId: "P2anjDqtRizIB4GU4GxAcTPGPYfg",
      contentBaseUrl: "https://static.descope.com/pages",
    },
    "cname.euc1.descope.com.": {
      env: "new",
      projectId: "Peuc12mHPwXwLtSLMMzoAU8iQ2AGqa26",
      contentBaseUrl: "https://static.descope.com/pages",
    },
  };

  const { projectId, contentBaseUrl, env } = conf[backendHost];
  localStorage.setItem("backendHost", backendHost);
  localStorage.setItem("env", env);

  if (projectId !== localStorage.getItem("projectId")) {
    localStorage.removeItem("DSR");
    localStorage.removeItem("DS");
    localStorage.setItem("projectId", projectId);
  }
  localStorage.setItem("base.content.url", contentBaseUrl);

  return (
    <AuthProvider
      projectId={projectId}
      baseUrl={"https://auth.tom-sso.descope.team"}
      contentBaseUrl={contentBaseUrl}
    >
      <App />
    </AuthProvider>
  );
};

const App = () => {
  return (
    <div className="App">
      <Container />
    </div>
  );
};

export default AppRoot;
